$utilities: (
  "opacity": (
    property: opacity,
    values: (
      60: 0.6
    )
  )
);
/* change the colors */
$primary: #02739c;
$alternate: #348cac;
$grey: #999;
$medium-grey: #ccc;
$danger: #e4a3a3;
$close-to-white: #eef4f8;
$close-to-alternate: #e8edf0;
$light-blue: #c0e1ed;
$medium-blue: #dae6ea;
$success: #54aa45;
$red-alternate: #bd2736;
$red: #dc3545;
$pink: #dc359c;
$light-grey: #f3f3f3;
$light-success: #d7e6cd;
$light-warning: #e6dfcd;
$light-danger: #efdada;
$lighter-success: #fafff6;
$lighter-warning: #fffcf6;
$lighter-danger: #fff4f4;
$warning: #c99006;
$text-color: #0d2a40;
$text-color-light: #585858;
$logo: #103a51;

@import "bootstrap";
@import "_modal";
@import "_interactive";
@import "_execution";
@import "@fontsource/roboto";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";
@import "react-diff-view/style/index.css";

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

/* Set black background color, white text and some padding */
footer {
  background-color: $logo;
  color: white;
  padding: 15px;
  font-size: 10px;
}

footer a {
  color: white;
  text-decoration: none;
}

footer a:hover {
  color: white;
  text-decoration: underline;
}

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: $text-color;
  min-height: 100%;
}

.MuiButton-root {
  color: #0d2a40 !important;
  border-color: #0d2a40 !important;
}

/* Remove the navbar's default margin-bottom and rounded borders */
.navbar {
  margin-bottom: 0;
  border-radius: 0;
  padding: 0 20px !important;
  background-color: $close-to-white;
}

.table_view_navbar {
  margin: 0;
  border-radius: 0;
  background-color: transparent;
  padding: 0 !important;
}

nav img {
  height: 30px;
  margin-bottom: 4px;
}

footer img {
  height: 20px;
}

.app_content {
  padding: 15px 20px;
  min-height: calc(100vh - 108px);
}

.header {
  height: 60px;
  background-position: right;
  background-repeat: no-repeat;
}

.header img {
  height: 50px;
  margin: 5px;
}

@media screen and (min-width: 1200px) {
  .header {
    background-image: url("../resources/top_background.jpg");
    height: 120px;
  }

  .header img {
    height: 70px;
    margin: 25px;
  }
}

/* On small screens, set height to 'auto' for sidenav and grid */
@media screen and (max-width: 767px) {
  .sidenav {
    height: auto;
    padding: 15px;
  }

  .row.content {
    height: auto;
  }

  .header {
    display: none;
  }
}

.center_div {
  margin: 0 auto;
  text-align: center;
}

.fixed_bottom {
  position: fixed !important;
  bottom: 5px;
  right: 30px;
}

/*Ovwerwrite to Bayer Primary colors*/
.btn-primary {
  background-color: $primary;
  border-color: $primary;
}

.btn-primary:hover {
  background-color: $alternate !important;
  border-color: $alternate !important;
}

.btn-outline-primary:hover {
  background-color: $alternate !important;
  border-color: $alternate !important;
  color: #fff !important;
}

.btn-outline-primary {
  color: $alternate !important;
  border-color: #02739c !important;
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  background-color: $primary;
  border-color: $primary;
  color: #fff !important;
}

.button-color {
  color: $primary !important;
}

.primary_color {
  color: $primary;
}

.secondary_color,
.spinner_color {
  color: $alternate;
}

.grey_color {
  color: #999;
}

.alert_error,
.alert_success {
  width: 30%;
  visibility: visible;
}

.repository_list_element {
  word-break: break-all;
  max-width: 120px !important;
}

.link_center_align {
  text-align: center;
}

.red_form {
  color: red !important;
}

.MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent !important;
}

.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: rgba(63, 81, 181, 0.08) !important;
}

.repository_name {
  font-size: 22px;
}

.repository_name_button {
  margin-left: 75px !important;
}

.button_without_text_transform {
  text-transform: none !important;
  padding: 0 !important;
  margin-top: 10px;
}

.button_without_text_transform:hover {
  background-color: transparent !important;
}

.repo_name_div {
  display: inline-block;
  margin-top: 10px;
}

.dot_menu_browse_repository {
  height: 30px !important;
}

.inner_menu_button {
  padding: 2px 3px !important;
  margin: 4px 5px !important;
}

.inner_menu_button.MuiButton-root {
  min-width: 0px !important;
  margin: 15px 0 !important;
}

.browse-pmx-activity-container {
  height: calc(90vh - 103px);
}

.table-view-list-container {
  height: 100%;
  width: 100%;
}

.browser_repository_table .MuiDataGrid-row .MuiCheckbox-root:not(.Mui-checked),
.browser_repository_table
  .MuiDataGrid-columnHeaderWrapper
  .MuiCheckbox-root:not(.Mui-checked) {
  opacity: 0 !important;
}

.browser_repository_table .MuiDataGrid-row:hover .MuiCheckbox-root,
.browser_repository_table
  .MuiDataGrid-columnHeaderWrapper:hover
  .MuiCheckbox-root {
  opacity: 0.8 !important;
}

.table_view_upper_menu {
  margin-bottom: 10px;
}

.table_view_upper_menu .MuiButton-root {
  margin-right: 10px;
  margin-bottom: 5px;
}

.pmx-activity-row-container {
  max-height: 97%;
  height: 100%;
}

#row_repository_details {
  .MuiCollapse-root {
    position: absolute;
    background-color: white;
    z-index: 1000;
    width: 100%;
  }
}

.create_user_button {
  margin-right: 10px;
}

.username_footer_div {
  text-align: center;
}

.list_objects_container {
  min-height: 400px;
  width: 100%;
}

.conversation_drawer {
  width: 1px !important;
  height: 1px !important;
  left: 700px !important;
}

.conversation_drawer .MuiPaper-root {
  width: 700px;
  padding: 10px;
}

.conversation_drawer .comment_conversation_wrapper {
  border: 2px #05ace7 dotted;
  padding: 5px;
  margin: 10px;
  border-radius: 5px;
}

.conversation_drawer .comment_conversation_reference_file_name {
  color: #05ace7;
  font-weight: bold;
  font-size: 11px;
  text-transform: none;
  margin-right: 10px;
  margin-bottom: 5px;
}

.conversation_drawer .screenshot_attach {
  font-size: 8px;
  min-width: 0px !important;
  padding: 5px !important;
  color: #05ace7;
  margin-top: 0px !important;
}

.clear_float {
  clear: both;
}

.comment_edit_action_holder .blockquote,
.conversation_drawer .blockquote {
  padding: 5px;
  margin: 5px;
  background-color: $light-blue;
  font-size: 10px;
  font-style: italic;
  color: #666666;
  width: 100%;
}

.row_for_adding_data {
  margin: 30px 0 10px 0 !important;
}

.small_mui_button {
  padding: 0 !important;
  margin-bottom: 10px !important;
  min-width: 30px !important;
}

#close_comment_drawer {
  color: #333;
  min-width: 20px !important;
}
.comment_editor {
  background-color: white;
}
.comment_editor .ql-editor {
  min-height: 200px;
}

.comment_conversation_inner img {
  max-width: 440px;
  display: block;
  cursor: pointer;
}

.drop_comment_file,
.drop_comment_action {
  background-color: #c0e1ed;
  padding: 5px 10px;
  margin: 5px 0;
  font-size: 12px;
  font-style: italic;
  clear: both;
}

.drop_comment_action {
  background-color: #c0edc3;
}

.drop_comment_file_invalid {
  background-color: #e4a3a3;
}

.drop_comment_file_warning {
  background-color: #e6d5bd;
}

.no_line_hight {
  line-height: 15px !important;
}

.preview_display_image {
  max-width: 500px;
}

.like_a_button {
  border: none;
  white-space: normal;
  display: contents;
  font: inherit;
  margin: 0;
  padding: 0;
  outline: none;
  outline-offset: 0;
  color: blue;
  cursor: pointer;
  text-align: left;
  text-decoration: underline;
  background: transparent;
  word-break: break-all;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: normal;
}

.login_grey_color_row label,
.login_grey_color_row input {
  color: #bbb !important;
}

.small_border {
  border: 1px solid black;
  padding: 5px;
}

.no_background {
  background: none !important;
}

.comment_user_details {
  font-size: 10px;
  color: #999;
}

.logo_navbar {
  color: #02739c !important;
  font-weight: bold;
  font-size: 30px !important;
}

.dropdown-menu {
  margin-left: -90px !important;
  display: block;
  visibility: collapse;
}
.dropdown-menu.show {
  visibility: visible;
}

.comments_icon {
  margin-right: 8px;
}

.tree-view-column {
  height: 100%;
  overflow-y: scroll;
}

.tree-view-column:not(:hover)::-webkit-scrollbar {
  display: none;
}

.tree-view-column:not(:hover) {
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: none;
}

.historical_tree_view {
  height: 90%;
}

.close_tree_view_button {
  height: 100%;
  padding: 2px;
  border: 0px;
  color: $grey;
}

.table_view_column,
.table_view_column_closed {
  width: 70%;
  display: inline-block;
}

.is_valid .description_icon,
.is_valid_clean,
.is_valid_circle {
  color: $success;
}

.is_invalid {
  color: $red;
}

#close_comment_drawer {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: white;
}

.actual_drop_target {
  border: 2px dotted $grey;
}

.comment_in_menu_table_view {
  display: block !important;
}

.table_view_comment_menu {
  color: $alternate;
}

.comment_selected_wrapper {
  background-color: $close-to-white;
  border-color: #000 !important;
}

.comment_reply_link {
  text-decoration: underline !important;
  cursor: pointer !important;
  margin: 0 !important;
  padding: 0 !important;
  text-transform: none !important;
  text-align: left !important;
}

.full_100_width {
  width: 100%;
}

.comment_reply_link .small_mui_button {
  margin: 0 !important;
}

.alert-dismissible {
  z-index: 5000;
}

.btn-danger {
  background-color: $red;
  border-color: $red;
  color: white;
}

.btn-danger:hover {
  background-color: $red-alternate !important;
  border-color: $red-alternate !important;
}

.parent_row_selected_action {
  background-color: $close-to-white !important;
  border: 2px solid #000 !important;
}

.notification_area {
  position: absolute;
  top: 50px;
  right: 100px;
  background-color: white;
  border: 1px solid $close-to-white;
  z-index: 6000;
  box-shadow: 0px 5px 10px 1px rgb(0 0 0 / 10%);
}

.notification_under_holder {
  min-width: 300px;
  padding: 0 10px;
}

.notification_all_read {
  float: right;
}

.notification_item {
  clear: both;
  width: 100%;
  border-bottom: 1px solid $close-to-white;
  background-color: $light-grey;
}

.notification_item_area {
  float: left;
  width: 560px;
  padding: 3px 3px 3px 10px;
  word-wrap: break-word;
  overflow: visible;
  cursor: pointer;
}

.notification_item_area:hover {
  opacity: 0.7;
}

.notification_item_date,
.uploading_info_details {
  color: $grey;
  font-size: 11px;
}

.notification_item_mark {
  color: $primary;
  float: right;
  height: 100%;
  padding: 0px 10px;
  background-color: white;
  cursor: pointer;
  line-height: 46px;
  cursor: pointer;
}

.notification_item_mark:hover {
  background-color: $light-grey;
}

.notification_item_mark_read {
  color: $grey;
}

.notification_error {
  background-color: $light-danger;
}

.notification_success {
  background-color: $light-success;
}

.notification_warning {
  background-color: $light-warning;
}

.notification_error_read {
  background-color: $lighter-danger;
}

.notification_success_read {
  background-color: $lighter-success;
}

.notification_warning_read {
  background-color: $lighter-warning;
}

#refersh_user_list {
  margin-bottom: 10px;
}

.comment_conversation_inner blockquote {
  margin-left: 15px;
  padding-left: 5px;
  border-left: 3px solid $grey;
}

.notification_item_area_unread {
  font-weight: bold;
}

.notification_item_date_unread {
  color: $primary;
  font-weight: bold;
}

.styled_data_grid {
  .MuiDataGrid-renderingZone {
    max-height: none !important;
    min-height: 10px !important;
  }
  .MuiDataGrid-cell {
    max-height: none !important;
    min-height: 10px !important;
  }
  .MuiDataGrid-row {
    max-height: none !important;
    min-height: 100px !important;
  }
}

.inner_notification_button_div {
  margin: 0 3px;
}

#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {
  opacity: 0.7;
}

#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.table_view_navbar {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  background-color: #fff !important;
  color: $text-color !important;
}

.refresh_item {
  color: $text-color !important;
  cursor: pointer;
  margin-right: 15px;
}

.table_view_svg {
  vertical-align: top;
}

.nav_item_button {
  cursor: pointer;
  color: $text-color !important;
}

.action_history_table {
  font-size: 11px;
}

.warning_clean {
  color: $warning !important;
}

.table_view_navbar .nav-link {
  color: $text-color !important;
}

.table_view_navbar .nav-link.disabled {
  color: $grey !important;
}

.table_details_spectrum {
  size: 10px;
}

.action_list_element {
  overflow: hidden;
  word-wrap: break-word;
}

.link-decoration {
  color: $primary;
  cursor: pointer;
  text-decoration: underline;
}

.qc_circle_flag {
  width: 12px;
}

.upper_menu a.navbar-brand {
  opacity: 1;
}

.folder_icon {
  color: #77c9ea;
}

.MuiDataGrid-root {
  border: none !important;
}

.notification_menu_item {
  margin-right: 10px;
}

/******PMX Inner Menu New******/
.upper_menu {
  background-color: $medium-blue;
}

.side_menu {
  float: left;
  flex: 5;
  background-color: $close-to-white;
  position: relative;
  overflow: hidden;
  padding: 0;
}

.side_menu_collapsed {
  flex: 1;
}

.pmx_app_content {
  float: right;
  flex: 29;
}

.bottom_collapse_menu {
  position: absolute !important;
  bottom: 2px;
  width: 100%;
}
.pmx_bradcrumbs {
  padding-bottom: 15px;
}

.side_menu_item {
  width: 100%;
}
.side_menu_item a,
.side_menu_item button {
  padding: 15px 10px;
  width: 100%;
  text-decoration: none;
  color: $text-color;
}

.side_menu_item svg {
  margin-right: 10px;
}

.side_menu_item:hover {
  background-color: $close-to-alternate;
}

.side_menu_item:hover a,
.side_menu_item:hover button {
  color: $alternate;
}

.pmx_active_tab {
  background-color: white;
  font-weight: 500;
}

.pmx_active_tab a,
.pmx_active_tab button {
  color: $logo;
}

.side_menu_item {
  cursor: pointer;
}

.brand_activity_name_menu_item svg {
  margin-left: 10px;
}

.side_menu button {
  text-align: left;
}

.side_menu_collapsed svg {
  margin: 0 auto;
}
.side_menu_collapsed a,
.side_menu_collapsed button {
  text-align: center;
}

.brand_activity_name_menu_item {
  cursor: pointer;
  background-color: $logo;
  text-align: center;
}
.brand_activity_name_menu_item button,
.brand_activity_name_menu_item:hover button {
  color: white;
  text-align: center;
}

.brand_activity_name_menu_item:hover {
  background-color: #02739c;
  color: white;
}

.MuiBreadcrumbs-ol .spinner_color {
  margin-left: 300px;
}

.pdf_preview {
  height: calc(100vh - 250px);
  border: 1px solid $medium-grey;
}

.html_preview {
  border: 1px solid $medium-grey;
  background-color: white;
  overflow-y: scroll;
  height: calc(100vh - 250px);
  padding: 3px;
}

.small_validity_icon {
  height: 20px !important;
}

.red {
  color: $red-alternate;
}

.outdated_validity_badge_content {
  color: $pink !important;
}

.user_validity_badge_content {
  color: $text-color !important;
}
